<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="'PRODUCTOS'"
      :subtitle="'Siga los pasos para completar el proceso'"
      :hide-buttons="false"
    >
      <tab-content title="Datos Generales" class="mb-5" :before-change="validateGeneralData">
        <form data-vv-scope="general-data">
          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información general</vs-divider>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-5">
              <label for="institution" class="w-full select-large">Institución</label>
              <v-select
                id="institution"
                name="institution"
                label="name"
                index="id"
                :options="institutions"
                :placeholder="'Seleccione..'"
                :searchable="true"
                v-model="entryModel.institution"
                v-validate="'required'"
                @input="getCategories(entryModel.institution)"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.name") }}</span>
              <br/>
            </div>
            <div class="vx-col md:w-1/2 w-full mt-5">
              <label for="category" class="w-full select-large">Categoría</label>
              <v-select
                id="category"
                name="category"
                label="name"
                index="id"
                :options="categories"
                :placeholder="'Seleccione..'"
                :searchable="true"
                v-model="entryModel.category"
                v-validate="'required'"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.category") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="title" class="w-full select-large">Título</label>
              <vs-input
                id="title" name="title" v-model="entryModel.title" v-validate="'required'" class="w-full"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.title") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full">
              <label for="name" class="w-full select-large">Nombre</label>
              <vs-input
                id="name" name="name" v-model="entryModel.name" v-validate="'required'" class="w-full"
                autocomplete="off"
              />
              <span class="text-danger">{{ errors.first("general-data.name") }}</span>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
              <label for="featured" class="w-full select-large">Mostrar formulario</label>
              <vs-select
                  id="featured" name="featured"
                  v-model="entryModel.show_form" v-validate="'required'"
                  class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.show_form") }}</span>
              <br/>
            </div>
            <div v-if="entryModel.show_form" class="vx-col md:w-1/2 w-full">
              <label for="featured" class="w-full select-large">Mostrar formulario</label>
              <vs-select
                  id="featured" name="featured"
                  v-model="entryModel.form_placement" v-validate="'required'"
                  class="w-full select-large"
              >
                <vs-select-item :key="'on_banner_bar'" :value="'on_banner_bar'" :text="'En banner'" class="w-full"/>
                <vs-select-item :key="'before_content'" :value="'before_content'" :text="'Antes del contenido'" class="w-full"/>
                <vs-select-item :key="'after_content'" :value="'after_content'" :text="'Después del contenido'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.form_placement") }}</span>
              <br/>
            </div>
          </div>

          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de producto</vs-divider>
          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="description" class="w-full select-large">Descripción</label>
              <editor id="description" :content.sync="entryModel.description.html" :resourceType="resource"/>
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <label for="related_products" class="w-full select-large">Productos relacionados</label>
              <v-select
                id="related_products"
                name="related_products"
                multiple
                label="name"
                index="id"
                :options="relatedProducts"
                :placeholder="'Seleccione los elementos..'"
                :searchable="true"
                v-model="entryModel.related_products"
                class="w-full"
              />
              <br/>
            </div>
          </div>

          <div class="vx-row">
            <div class="vx-col md:w-1/4 w-full">
              <label for="featured" class="w-full select-large">Destacado</label>
              <vs-select
                id="featured" name="featured" v-model="entryModel.featured" v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.featured") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/4 w-full">
              <label for="show_product" class="w-full select-large">Mostrar producto</label>
              <vs-select
                id="show_product" name="show_product" v-model="entryModel.show_product" v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.show_product") }}</span>
              <br/>
            </div>

            <div class="vx-col md:w-1/4 w-full">
              <label for="priority" class="w-full select-large">Prioridad</label>
              <vs-input
                type="number"
                id="priority"
                name="priority"
                min="1"
                max="10"
                v-model="entryModel.priority"
                class="w-full"
              />
              <span class="text-danger">{{ errors.first("general-data.priority") }}</span>
              <br/>
            </div>
            <div class="vx-col md:w-1/4 w-full">
              <label for="approved" class="w-full select-large">Aprobado</label>
              <vs-select
                id="approved" name="approved" v-model="entryModel.approved" v-validate="'required'"
                class="w-full select-large"
              >
                <vs-select-item :key="true" :value="true" :text="'Si'" class="w-full"/>
                <vs-select-item :key="false" :value="false" :text="'No'" class="w-full"/>
              </vs-select>
              <span class="text-danger">{{ errors.first("general-data.approved") }}</span>
              <br/>
            </div>
          </div>

          <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Publicación</vs-divider>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full mt-5">
              <label for="publish_at" class="w-full select-large">Fecha y hora de publicación</label>
              <flat-pickr
                :config="configDateTimePicker"
                id="publish_at"
                v-model="entryModel.publish_at"
                class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>

            <div class="vx-col md:w-1/2 w-full mt-5">
              <label for="expire_at" class="w-full select-large">Fecha y hora de expiración</label>
              <flat-pickr
                :config="configDateTimePicker"
                id="expire_at"
                v-model="entryModel.expire_at"
                class="w-full flatpickr flatpickr-input"
              />
              <br/>
            </div>
          </div>
        </form>
      </tab-content>

      <!-- tab 2 content -->
      <tab-content title="Contenido" class="mb-5">
        <div class="vx-row">
          <div class="vx-col w-full">
            <label class="w-full select-large">Defina el contenido correspondiente a la producto</label>
            <vs-divider class="vx-col w-full mt-5"/>
            <builder :html.sync="entryModel.content" :resource="resource"/>
          </div>
        </div>
      </tab-content>

      <!-- tab 3 content -->
      <tab-content title="Medios" class="mb-5">
        <label class="w-full select-large">Inserte la imagen que se verá cuando se comparta un link de la página</label>
        <vs-divider class="vx-col w-full"/>

        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-1'"
              :maxNumberOfFiles="1"
              @files-removed="removedFiles('open_graph')"
              @files-updated="openGraphUpload"
              :downloadedFiles="entryModel.media.open_graph"
            />
          </div>
        </div>

        <label class="w-full select-large">Inserte el banner de la página</label>
        <vs-divider class="vx-col w-full"/>

        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-2'"
              :maxNumberOfFiles="1"
              @files-removed="removedFiles('banner')"
              @files-updated="bannerUpload"
              :downloadedFiles="entryModel.media.banner"
            />
          </div>
        </div>

        <label class="w-full select-large">Inserte el thumbnail de la página</label>
        <vs-divider class="vx-col w-full"/>

        <div class="vx-row">
          <div class="vx-col w-full mb-5">
            <uploader
              :classFile="'file-upload-3'"
              :maxNumberOfFiles="1"
              @files-removed="removedFiles('thumbnail')"
              @files-updated="thumbnailUpload"
              :downloadedFiles="entryModel.media.thumbnail"
            />
          </div>
        </div>
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <vs-button
            v-if="props.activeTabIndex > 0"
            @click.native="props.prevTab()"
            class="wizard-footer-left finish-button"
          >
            Anterior
          </vs-button>
        </div>

        <div class="wizard-footer-right">
          <vs-button
            v-if="!props.isLastStep"
            class="wizard-footer-right finish-button"
            @click.native="props.nextTab()"
          >Siguiente
          </vs-button>
          <vs-button
            v-else
            class="wizard-footer-right finish-button"
            @click.native="props.nextTab()"
            @click="createOrUpdateEntry"
          >Enviar
          </vs-button>
          <vs-button
            color="danger"
            type="border"
            class="wizard-footer-right finish-button mr-3"
            @click="goToEntriesList"
          >Cancelar
          </vs-button>
        </div>
      </template>
    </form-wizard>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import CoreClient from "../../utils/coreClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import Configurations from "./../../utils/configurations";
  import Upload from "./../../utils/upload";
  import Editor from "./../../components/Editor.vue";
  import Builder from "./../../components/Builder.vue";
  import Uploader from "./../../components/Uploader.vue";
  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import {Validator} from 'vee-validate';
  import { EventBus } from '../../utils/event-bus.js';

  let notifier = new Notifier();
  let configurations = new Configurations();
  const INSTITUTIONS_CLIENT = new CoreClient("institutions");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("products");
  const ENTRY_CLIENT = new EntriesClient("products");
  const UPLOAD = new Upload("resource");

  let dict = [
    "institution", "category", "name", "title", "featured", "show_product", "priority", "approved"
  ].reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        configDateTimePicker: configurations.configDatePicker(),
        resource: "products",
        institutions: [],
        relatedProducts: [],
        categories: [],
        entryModel: {
          institution: null,
          category: null,
          name: null,
          title: null,
          description: {
            html: null
          },
          media: {
            open_graph: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            },
            banner: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            },
            thumbnail: {
              url: null,
              meta: {
                title: null,
                description: null,
              },
            },
          },
          content: {
            assets: null,
            html: null,
            css: null
          },
          related_products: [],
          form: {
            fields: []
          },
          show_product: false,
          featured: false,
          approved: true,
          priority: 10,
          publish_at: null,
          expire_at: null,
          show_form: true,
          form_placement: 'on_banner_bar',
        },
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id).then(() => {
          this.$vs.loading.close();
        });
      } else {
        this.$vs.loading();
        this.getInstitutions(false)
          .then(() => {
            this.getRelatedProducts(false);
          })
          .then(() => {
            this.$vs.loading.close();
          });
      }
    },

    methods: {
      async getInstitutions(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await INSTITUTIONS_CLIENT.all()
          .then(response => {
            this.institutions = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getRelatedProducts(showLoading = true) {
        if (showLoading) this.$vs.loading();
        await ENTRY_CLIENT.all({queryStrings: {representation_type: "simple"}})
          .then(response => {
            this.relatedProducts = response.data;
            if (showLoading) this.$vs.loading.close();
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getCategories(institution, showLoading = true) {
        if (institution !== null && institution !== undefined && institution !== "") {
          if (showLoading) this.$vs.loading();

          await ATTRIBUTE_OPTIONS_CLIENT.categories({
            pathParameters: {institutionId: institution.id},
            queryStrings: {representation_type: "simple"}
          })
            .then(response => {
              this.categories = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });

        } else {
          this.categories = [];
          this.entryModel.category = null;
        }
      },

      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            this.getInstitutions(false)
              .then(() => {
                this.getCategories(response.data.institution, false)
                  .then(() => {
                    this.getRelatedProducts(false)
                        .then(() => {
                          Object.assign(this.entryModel, response.data);
                        });
                  });
              });
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.$vs.loading();
        EventBus.$emit('saveBuilder');
        let content = {};
        content["gjs-html"] = this.entryModel.content["html"];
        content["gjs-assets"] = this.entryModel.content["assets"];
        content["gjs-css"] = this.entryModel.content["css"];

        let sendData = {};

        this.cleanModel();
        Object.assign(sendData, this.entryModel);
        sendData.content = content;

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: sendData})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToEntriesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        } else {
          await ENTRY_CLIENT.create({data: sendData})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToEntriesList();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      async openGraphUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.open_graph.url = response;
              this.entryModel.media.open_graph.meta.title = files[0].meta.name;
              this.entryModel.media.open_graph.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
        }
      },

      async bannerUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.banner.url = response;
              this.entryModel.media.banner.meta.title = files[0].meta.name;
              this.entryModel.media.banner.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
        }
      },

      async thumbnailUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          this.$vs.loading();
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.thumbnail.url = response;
              this.entryModel.media.thumbnail.meta.title = files[0].meta.name;
              this.entryModel.media.thumbnail.meta.description = files[0].meta.caption;
              this.$vs.loading.close();
            })
            .catch(error => {
              this.$vs.loading.close();
              notifier.notification("error");
            });
        }
      },

      async removedFiles(files) {
        let mediaType = this.entryModel.media;
        let body = mediaType[files].url;

        body = JSON.stringify({url: body});

        await UPLOAD.removeFiles({data: body}).then(response => {
          mediaType[files] = {
            url: null,
            meta: {
              title: null,
              description: null
            }
          }
        }).catch(error => {
          notifier.notification("error");
        });
      },

      goToEntriesList() {
        this.$router.push({name: "products"});
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },

      validateGeneralData() {
        return new Promise((resolve, reject) => {
          this.$validator.validateAll("general-data")
            .then(result => {
              if (result) {
                resolve(true);
              } else {
                reject("Please fix invalid fields");
              }
            })
        });
      }
    },
    components: {
      Editor,
      FormWizard,
      TabContent,
      flatPickr,
      Builder,
      Uploader,
      "v-select": vSelect
    }
  };
</script>
